<template>
   <section>
      <q-permissions
         :__PERMISSIONS__="permStatus"
         :page="'modules'"
         :index="'index'"
      />
      <div v-if="permStatus" class="">
         <b-col>
            <b-card no-body class="flex gap-8 py-2">
               <div class="mx-1">
                  <!-- Table Top -->
                  <b-row>
                     <!-- Per Page -->
                     <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                     >
                        <label>Entrées</label>
                        <v-select
                           v-model="state.perPage"
                           :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                           :options="perPageOptions"
                           :clearable="false"
                           class="per-page-selector d-inline-block ml-50 mr-1"
                        />
                        <b-button
                           variant="primary"
                           v-b-modal.add-fournier
                           @click="rediriger"
                        >
                           <q-button-created :title="'Nouveau module'" />
                        </b-button>
                     </b-col>

                     <!-- Search -->
                     <b-col cols="12" md="6">
                        <div
                           class="d-flex align-items-center justify-content-end"
                        >
                           <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                 <feather-icon icon="SearchIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                 v-model="state.filtre"
                                 class="d-inline-block mr-1"
                                 placeholder="Rechercher par : fourniser, date, numero de telephone"
                              />
                           </b-input-group>
                        </div>
                     </b-col>
                  </b-row>
               </div>

               <!-- Loader -->
               <!-- <q-loader-table
                  :success="state.success"
                  :empty="state.empty"
                  :warring="state.warring"
               /> -->

               <b-table
                  responsive
                  hover
                  primary-key="id"
                  show-empty
                  :items="modules"
                  :currentPage="state.currentPage"
                  :perPage="state.perPage"
                  :fields="tableColumns"
                  :filter="state.filter"
               >
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                     <div class="flex text-nowrap py-1">
                        <feather-icon
                           v-b-modal.edit-fournier
                           :id="`invoice-row-${data.item.id}-send-icon`"
                           icon="Edit3Icon"
                           class="cursor-pointer mr-1"
                           size="16"
                           @click="update(data.item)"
                        />

                        <b-tooltip
                           title="modifier le fournisseur"
                           class="cursor-pointer"
                           :target="`invoice-row-${data.item.id}-send-icon`"
                        />

                        <feather-icon
                           icon="TrashIcon"
                           :id="`invoice-row-${data.item.id}-trash-icon`"
                           size="16"
                           class=" cursor-pointer"
                        />
                        <b-tooltip
                           title="supprimer le fournieur"
                           :target="`invoice-row-${data.item.id}-trash-icon`"
                        />
                     </div>
                  </template>
               </b-table>

               <!-- Paginator -->
               <div class="mx-2 mb-2">
                  <b-row>
                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                     >
                        <span class="text-muted"></span>
                     </b-col>
                     <!-- Pagination -->
                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                     >
                        <b-pagination
                           v-model="state.currentPage"
                           :total-rows="modules.length"
                           :per-page="state.perPage"
                           first-number
                           last-number
                           class="mb-0 mt-1 mt-sm-0"
                           prev-class="prev-item"
                           next-class="next-item"
                           align="right"
                        >
                           <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                           </template>
                           <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                           </template>
                        </b-pagination>
                     </b-col>
                  </b-row>
               </div>
            </b-card>
         </b-col>
      </div>
   </section>
</template>

<script>
import {
   BRow,
   BCol,
   BModal,
   BFormInput,
   BFormGroup,
   BButton,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';
import CryptoJS from 'crypto-js';
import QPermissions from '../../access/qPermissions.vue';
import { computed, reactive, ref } from '@vue/composition-api';
import QButtonCreated from '@/components/qButtonCreated.vue';
export default {
   components: {
      CryptoJS,
      VueTelInput,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BModal,
      BFormGroup,
      VBModal,
      BForm,
      BLink,
      BFormCheckbox,
      BInputGroup,
      BInputGroupAppend,
      BImg,
      required,
      email,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      axios,
      QPermissions,
      QButtonCreated,
   },
   directives: {
      Ripple,
   },
   setup(props, { root }) {
      const tableColumns = [
         // { key: 'id', label: 'N', sortable: true },
         { key: 'index', label: 'N°', sortable: true },
         { key: 'libelle', label: 'Libelle' },
         { key: 'prix', label: 'Montant' },
         { key: 'description', label: 'description' },
         { key: 'actions' },
      ];
      const perPageOptions = [30, 40, 50, 100, 150, 500];
      const state = reactive({
         currentPage: 1,
         perPage: 30,
         filter: '',
         success: false,
         warring: false,
         empty: false,
      });

      const permStatus = computed(() => {
         return root.$store.state.qPermissions.statuts;
      });
      return {
         permStatus,
         tableColumns,
         perPageOptions,
         state,
      };
   },

   data() {
      return {
         elements: '',
         selectedAll: [],
         selected: [],
         edit_description: '',
         valideEditPrixNumber: false,
         valideEditLibelle: false,
         edit_libelle: '',
         valideEditPrix: false,
         edit_prix: '',
         config: {
            headers: {
               Accept: 'application/json',
            },
         },
         modules: '',
      };
   },
   async mounted() {
      document.title = 'Liste des modules';
      try {
         await axios
            .get(URL.MODULES)
            .then((response) => {
               this.returnData = response;
               this.modules = this.returnData.data.module_et_permission;
               console.log(this.modules);
            })
            .catch((error) => {
               console.log(error.response.data.errors);
            });
      } catch (error) {
         console.log(error);
      }

      try {
         await axios
            .get(URL.PERMISSION_LIST)
            .then((response) => {
               this.returnData = response;
               this.elements = this.returnData.data[0].element;
            })
            .catch((error) => {
               console.log(error);
            });
      } catch (error) {
         console.log(error);
      }
   },
   methods: {
      rediriger() {
         this.$router.push('/modules/create');
      },
      update(indice) {
         this.edit_description = this.modules[indice].description;
         this.edit_libelle = this.modules[indice].libelle;
         this.edit_prix = this.modules[indice].montant;

         for (
            let index = 0;
            index < this.modules[indice].permissions.length;
            index++
         ) {
            this.selected.push(this.modules[indice].permissions[index].name);
         }
         var ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(this.modules[indice]),
            'qenium 123'
         ).toString(ciphertext);
         localStorage.setItem('aUpdate', ciphertext);
         this.$router.push('/modules/update');
      },
      format_date(value) {
         if (value) {
            return moment(String(value)).format('DD / MM / YYYY');
         }
      },
      all() {
         for (let index = 0; index < this.elements.length; index++) {
            for (
               let index1 = 0;
               index1 < this.elements[index].permissions.length;
               index1++
            ) {
               const perm = this.elements[index].permissions[index1].name;
               for (
                  let index2 = 0;
                  index2 < this.selectedAll.length;
                  index2++
               ) {
                  if (
                     perm.lastIndexOf(this.selectedAll[index2]) >= 0 &&
                     this.isInArray(perm, this.selected) === false
                  ) {
                     this.selected.push(perm);
                  }
               }
            }
         }

         let inter = [];

         for (let index = 0; index < this.selected.length; index++) {
            let ver = false;
            for (let index1 = 0; index1 < this.selectedAll.length; index1++) {
               if (
                  this.selected[index].lastIndexOf(this.selectedAll[index1]) >=
                  0
               ) {
                  ver = true;
                  index1 = this.selectedAll.length;
               }
            }
            if (ver === true) {
               inter.push(this.selected[index]);
            }
         }
         this.selected = inter;
      },

      isInArray(value, array) {
         return array.indexOf(value) > -1;
      },
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.table-base {
   margin: 30px auto 0;
}

.tableau {
   box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
   width: 100%;
   margin: auto;
   border-radius: 13px;
}

.table-card thead tr {
   border-radius: 13px;
   background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
   background-color: rgb(68, 68, 68) !important;
   color: white;
}

.add-btn {
   position: absolute;
   right: 0;
   top: -50px;
   background-color: #450077;
}
.none {
   display: none;
}
.block {
   display: inline-block;
}
</style>
